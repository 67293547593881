import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.log(e);
  });
}

export const SignInButton = () => {
  const { instance } = useMsal();

  return (
    <button
      className="btn btn-text btn-text-orange small"
      onClick={() => handleLogin(instance)}
    >
      Log In
    </button>
  );
};
