// retrieve the configs from the env variables
const rcdsEndpointBaseUrl = process.env.REACT_APP_RCDS_ENDPOINT_BASE_URL;
const rcdSubscriptionKey = process.env.REACT_APP_RCDS_SUBSCRIPTION_KEY;

/**
 * Retrieving child data from RCDS using the 11 characters based child ID
 * @param {string} childID 
 * @returns 
 */
export async function rcdsGetChildByChildId(childID) {
    const $url = `${rcdsEndpointBaseUrl}/api/Sponsorship/children/${childID}/for-child-id/web?subscription-key=${rcdSubscriptionKey}`;
        
    try {
        const meta = await fetch(
            $url,
            {
                method: "GET"
            }
            );
        const childObj = await meta.json();
        return childObj;
    } catch (error) {
        return Promise.reject(error);
    }
}


/**
 * Unlocking child in rcds using the Lock ID 
 * returns child object json or failed Promise
 * 
 * @param {string} lockID 
 * @returns
 */
export async function rcdsUnlockChildByLockId(lockID) {
    const $url = `${rcdsEndpointBaseUrl}/api/Sponsorship/children/${lockID}/unlock?subscription-key=${rcdSubscriptionKey}&verbose=true`;
        
    try {
        const meta = await fetch(
            $url,
            {
                method: "PUT"
            }
            );
        const childObj = await meta.json();
        return childObj;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
}