import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../Auth/SignInButton";
import { Header } from "./Header";
import Profile from "../Auth/Profile";
import { Details } from "../Instance/Details";
import { InstanceList } from "../Instance/InstanceList";
import { Route, Switch, Link } from "react-router-dom";
import { SearchApp } from "../Search/SearchApp";
import { ChildUnlock } from "../ChildUnlock/ChildUnlock";

export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();

  const path = window.location.pathname;

  return (
    <>
      <div className="App">
        <Header />
        <Navbar bg={"light"} expand="lg" className="sg-swatch--wv-orange-40">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto ml-3" activeKey={path}>
              <Link to={"/"} className="mr-3">Dashboard</Link>|
              <Link to={"/child-unlock"} className="mr-3 ml-3">Child Unlock</Link>|
              <Link to={"/search"} className="mr-3 ml-3">Search</Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <div>{isAuthenticated ? <Profile /> : <SignInButton />}</div>
          </Navbar.Collapse>
        </Navbar>
        <main className="mt-4 mb-5">
          <Container fluid>
            <Switch>
              <Route path="/search" component={SearchApp}>
                <SearchApp />
              </Route>
              <Route path="/child-unlock" component={ChildUnlock}>
                <ChildUnlock />
              </Route>
              <Route path="/details/:id" component={Details}>
                <Details />
              </Route>
              <Route path="/">
                <InstanceList />
              </Route>
            </Switch>
          </Container>
        </main>
        <Navbar
          fixed="bottom"
          bg={"light"}
          expand="lg"
          className="sg-swatch--wv-orange-40 mt-5"
        >
          <div className="small">
            © 2021 World Vision, Inc. All rights reserved.
          </div>
        </Navbar>
      </div>
    </>
  );
};
