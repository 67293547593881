import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { SignInButton } from "../Auth/SignInButton";
import { Header } from "./Header";

export const AnnonPageLayout = () => {
  return (
    <>
      <Header />
      <Navbar bg={"light"} expand="lg" className="sg-swatch--wv-orange-40">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey="/"></Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <div>
            <SignInButton />
          </div>
        </Navbar.Collapse>
      </Navbar>
      <Container className="flex-grow-1 mt-5 pt-5">
        <div className="text-center hero">
          <p className="pt-5">
            <strong>Please Login to continue</strong>.
          </p>
          <div>
            <SignInButton />
          </div>
        </div>
      </Container>

      <Navbar
        fixed="bottom"
        bg={"light"}
        expand="lg"
        className="sg-swatch--wv-orange-40"
      >
        <div className="text-center hero">
          © 2021 World Vision, Inc. All rights reserved.
        </div>
      </Navbar>
    </>
  );
};
