import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { rcdsGetChildByChildId, rcdsUnlockChildByLockId } from "../../services/rcdsService";


export const ChildUnlock = () => {
    const [spinner, showSpinner] = useState(false);
    const [childID, setChildid] = useState(null);
    const [childInfo, setChildinfo] = useState(null);

    function handleChildIDFldChange(val) {
        const fldVal = val.target.value;
        setChildid(fldVal);
    }
    
    async function handleLoadChildData() {
        showSpinner(true);
        const childDataRequest = await rcdsGetChildByChildId(childID);
        setChildinfo(childDataRequest.data);
        showSpinner(false);
    }

    async function handleChildUnlockBtn() {
        showSpinner(true);

        const lockID = childInfo?.lockIdentifier;

        rcdsUnlockChildByLockId(lockID);

        setTimeout(async () => {  
            const childDataRequest = await rcdsGetChildByChildId(childID);
            setChildinfo(childDataRequest.data);
            showSpinner(false);
        }, 3000);
    }

    return (
        <>
            <Row className="justify-content-md-center mb-5">
                <Col md="1" className="">
                </Col>
                <Col md="5">
                    <h3>Child Unlock</h3>
                    <Form.Group className="mb-3" controlId="child-id">
                        <Form.Label>Child ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="child-id"
                            data-element="child-id"
                            defaultValue={childID}
                            onChange={handleChildIDFldChange}
                            placeholder="123456-1234"
                            autoComplete="off"
                            required={true}
                            />
                    </Form.Group>
                    <Button
                        size="sm"
                        key="load-data"
                        variant="outline-secondary"
                        className="btn-ghost-orange btn-small"
                        disabled={(!childID || spinner)}
                        onClick={(e) => {
                            e.preventDefault();
                            handleLoadChildData();
                        }}
                    >
                        Load Child Data
                    </Button>
                    {(childInfo && childInfo?.identifier) && (
                        <>
                            {childInfo?.lockIdentifier ? (
                                <Button
                                    size="sm"
                                    key="unlock-child"
                                    variant="outline-secondary"
                                    className="btn-ghost-orange btn-small ml-5 float-right"
                                    disabled={spinner}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleChildUnlockBtn();
                                    }}
                                    >
                                    Unlock Child
                                </Button>
                            ) : (
                                <>
                                    <div key={"warning"} className="text-success ml-5 float-right">
                                        Child Unlocked
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Col>
                <Col md="1" className="">
                    {spinner && (
                        <Container className="">
                            <div className="spinner--wv-star m-0">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                            </div>
                        </Container>
                    )}
                </Col>
            </Row>
            {(childInfo && childInfo.identifier) && (
                <Row className="justify-content-md-center">
                    <Col md="5">
                        <h6>Child Info</h6>
                        <pre>
                            {JSON.stringify(childInfo, null, 4)}
                        </pre>
                    </Col>
                </Row>
            )}
        </>
      );
}