import { FormField } from "./FormField";

export const OrderLineItem = ({
  itemCntr,
  item,
  handleFldChange,
  disabledState,
}) => {
  return (
    <>
      <FormField
        itemCntr={itemCntr}
        fldId="OrderLineType"
        label="Order Line Type1"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.OrderLineType}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="itemNumber"
        label="Item Number"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.ItemNumber}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="CommitmentNumber"
        label="Commitment Number"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.CommitmentNumber}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="CommitmentDetailNumber"
        label="Commitment Detail Number"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.CommitmentDetailNumber}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="Rate"
        label="Rate"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.Rate}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="Quantity"
        label="Quantity"
        element="OrderLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.Quantity}
      />
      {item.OrderLineType === "HonorCard" && (
        <fieldset>
          <h5>Shipping</h5>
          <FormField
            itemCntr={itemCntr}
            fldId="ShipToName"
            label="Shipping To Name"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToName
                ? item.Shipping.ShipToName
                : ""
            }
          />
          <FormField
            itemCntr={itemCntr}
            fldId="ShipToAddressLine1"
            label="Address Line 1"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToAddress.AddressLine1
                ? item.Shipping.AddressLine1
                : ""
            }
          />
          <FormField
            itemCntr={itemCntr}
            fldId="ShipToCity"
            label="City"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToAddress.City
                ? item.Shipping.ShipToAddress.City
                : ""
            }
          />
          <FormField
            itemCntr={itemCntr}
            fldId="StateOrProvince"
            label="State / Province"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToAddress.StateOrProvince
                ? item.Shipping.ShipToAddress.StateOrProvince
                : ""
            }
          />
          <FormField
            itemCntr={itemCntr}
            fldId="PostalCode"
            label="ZIP / Postal code"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToAddress.PostalCode
                ? item.Shipping.ShipToAddress.PostalCode
                : ""
            }
          />
          <FormField
            itemCntr={itemCntr}
            fldId="Country"
            label="Country"
            element="OrderLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={
              item.Shipping && item.Shipping.ShipToAddress.Country
                ? item.Shipping.ShipToAddress.Country
                : ""
            }
          />
        </fieldset>
      )}
    </>
  );
};
