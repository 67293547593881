import { useEffect, useState } from "react";
import { Instance } from "./Instance";
import { Row } from "react-bootstrap";
import { istGetInstancesByStartDate } from "../../services/istService";
import { metaGetInstancesByInstanceIDs } from "../../services/metaService";
import moment from "moment";

export const InstanceList = () => {
  const [instances, setInstances] = useState([]);
  const [instances_meta, setInstancesMeta] = useState([]);
  const [spinner, showSpinner] = useState(true);

  useEffect(() => {
    requestInstances();
  }, []); // eslint-disable-line


  /**
   * Load the instances list from the ist and instance meta services endpoints
   */
  async function requestInstances() {
    // get the 30 days ago date
    const start_date = moment().subtract(30, "d").format("YYYY-MM-DD");
    var data = await istGetInstancesByStartDate(start_date);
    let instanceIdsArr = [];
    let instance_arr = data.Results;

    if (data.ContinuationToken !== null && data.ContinuationToken.length > 1) {
      var continToken = data.ContinuationToken;
      do {
        var new_data = await istGetInstancesByStartDate(start_date, continToken);
        continToken = new_data.ContinuationToken;
        instance_arr.push(...new_data.Results);
      } while (
        new_data.ContinuationToken !== null &&
        new_data.ContinuationToken.length > 1
      );
    }

    // extract the instance IDs
    instance_arr.forEach((instance) => {
      instanceIdsArr.push(instance.InstanceId);
    });

    // retrieve the instances meta data
    const instances_meta_arr = await metaGetInstancesByInstanceIDs(instanceIdsArr);

    setInstancesMeta(instances_meta_arr);

    instance_arr = instance_arr.sort(
      (a, b) => moment(a.CreatedTime).unix() - moment(b.CreatedTime).unix()
    );

    let instances_new = [];
    instance_arr.map((instance, id) => {
      const metaObj = instances_meta_arr[instance.InstanceId];

      if (
        metaObj !== undefined &&
        (metaObj.OrderQueue === "CLOSED" ||
          metaObj.OrderQueue === "DCSCLOSED" ||
          metaObj.ResubmittedInstance)
      ) {
        // eslint-disable-next-line array-callback-return
        return;
      } else {
        instances_new.push(instance);
      }

      return instance_arr;
    });

    let i,
      j,
      itemsarr = [],
      chunk = 4;
    for (i = 0, j = instances_new.length; i < j; i += chunk) {
      itemsarr[i] = instances_new.slice(i, i + chunk);
    }

    setInstances(itemsarr);
    showSpinner(false);
  }

  return (
    <>
      {spinner ? (
        <div className="row">
          <div className="w-50 mx-auto">
            <div className="spinner--wv-star mb-0 mt-2">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
      ) : instances.length < 1 ? (
        <>
          <div className="row">
            <div className="mx-auto">
              <h3>No Instances found in the system</h3>
            </div>
          </div>
        </>
      ) : (
        instances.map((row, i) => (
          <Row key={i} className="mt-5 mb-5">
            {row.map((inst, x) => (
              <Instance
                id={inst.InstanceId}
                key={inst.InstanceId}
                instance={inst}
                meta={instances_meta[inst.InstanceId]}
              />
            ))}
          </Row>
        ))
      )}
    </>
  );
};
