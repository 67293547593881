// retrieve the configs from the env variables
const metaApiBaseUrl = process.env.REACT_APP_META_API_URL;
const metaApiFunctionKey = process.env.REACT_APP_META_API_FUNCTIONS_KEY;

const fetchHeaders = {
    "x-functions-key": metaApiFunctionKey,
};

/**
 * Retrieve the instances meta data based on the list of instances ids
 * 
 * @param {Array} instance_ids
 * @returns Array
 */
export const metaGetInstancesByInstanceIDs = async (instance_ids) => {    


    const instances_csv = instance_ids.join(",");

    // calling the metadata api
    const meta = await fetch(`${metaApiBaseUrl}?`, {
        method: "POST",
        headers: fetchHeaders,
        body: `{"Instances":"${instances_csv}"}`,
    });
    const result = await meta.json();

    let instances_meta_arr = [];
    result.forEach((instance) => {
        if (instance) {
        instances_meta_arr[instance.RowKey] = instance;
        }
    });

    return instances_meta_arr;
}


/**
 * Makes the call to the metadata API endpoint
 * to update or create new instance meta data
 * @param metaObj
 */
export const metaUpdateInstanceMeta = async (metaObj, instanceObj) => {
    
    try {
        // calling the metadata api
        await fetch(metaApiBaseUrl, {
            method: "POST",
            headers: fetchHeaders,
            body: JSON.stringify(metaObj),
        })
        .then(async (response) => {
            if (response.status === 200) {
                const metaObj = await metaGetMetaByInsanceID(instanceObj.InstanceId);
                return metaObj;
            }
        })
        .catch((error) => {
            console.log("HTTP error", error);
        });
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }

    return metaObj;
}


/**
 * Load the Instance Meta from the API
 * @param {string} instnce_id
 */
export const metaGetMetaByInsanceID = async (instnce_id) => {
    try {
        // calling the metadata api
        const meta = await fetch(
            `${metaApiBaseUrl}?Instance=${instnce_id}`,
            {
                method: "GET",
                headers: fetchHeaders,
            }
        );

        const metaObj = await meta.json();
        metaObj.Instance = metaObj.RowKey;

        return metaObj;
    } catch (error) {
        console.log(error);
        return Promise.reject(error);
    }
}