import React from "react";
import { useMsal } from "@azure/msal-react";

import { SignOutButton } from "./SignOutButton";
import { SignInButton } from "./SignInButton";
import { AuthProvider } from "./AuthProvider";

function Profile() {
  const { accounts } = useMsal();

  let user_token = <SignInButton />;

  if (accounts.length) {
    user_token = accounts[0] && accounts[0].name;
  }

  // const accessToken = AuthProvider();

  AuthProvider();

  return (
    <div>
      <span className="pr-4">{user_token}</span> | <SignOutButton />
    </div>
  );
}

export default Profile;
