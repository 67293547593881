import { getAccessToken } from "./authService";

// retrieve the configs from the env variables
const istSubscriptionKey = process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY;
const istApiBaseUrl = process.env.REACT_APP_IST_API_INSTANCES_URL


/**
 * Fetch a list of istnaces from the IST service using the startDate as a starting poing for the request 
 * 
 * @param {string} startDate Start Date
 * @param {string} continuationToken 
 * @returns 
 */
export const istGetInstancesByStartDate = async (startDate, continuationToken = "") => {
    var data = {};
    const token = await getAccessToken();

    // get the 30 days ago date
    // const start_date = moment().subtract(30, "d").format("YYYY-MM-DD");
    if (token) {
      const headers = {
        "Ocp-Apim-Subscription-Key": istSubscriptionKey,
        Authorization: "Bearer " + token,
      };

      try {
        const res = await fetch(
          `${istApiBaseUrl}/v1/instances/?status=failed&instanceIdPrefix=cco&fromTime=${startDate}&continuationToken=${continuationToken}`,
          {
            method: "GET",
            headers: headers,
          }
        );
        return await res.json();
      } catch (error) {
        console.log("Empty result");
        console.log(error);
      }
    }
    return data;
  }


/**
 * Retrieve the instance data from the API using the instance ID
 */
export const istGetInstanceByID = async (id) => {
    const token = await getAccessToken();

    if (token) {
        const headers = {
            "Ocp-Apim-Subscription-Key": istSubscriptionKey,
            Authorization: "Bearer " + token,
        };

        try {
            const res = await fetch(
            `${istApiBaseUrl}/v1/instances/${id}?showSimplifiedHistory=true&showHistory=true`,
            {
                method: "GET",
                headers: headers,
            }
            );

            const instance = await res.json();
            return instance;
        } catch (error) {
            console.log("Empty result");
            console.log(error);
        }
    }
    return null;
}