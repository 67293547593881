import { Form } from "react-bootstrap";

export const FormField = ({
  element = null,
  tree1 = null,
  disabledState = true,
  itemCntr,
  fldId,
  label,
  fldValue,
  handleFldChange,
}) => {
  return (
    <Form.Group className="mb-3" controlId={fldId + itemCntr}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        name={fldId}
        data-element={element}
        data-tree1={tree1}
        defaultValue={fldValue}
        onChange={handleFldChange}
        disabled={disabledState}
      />
    </Form.Group>
  );
};
