import { FormField } from "./FormField";

export const SupporterForm = ({
  supporterObj,
  handleFldChange,
  disabledState,
}) => {
  return (
    <>
      <FormField
        fldId="SupporterNumber"
        label="Supporter Number"
        element="Supporter"
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={supporterObj?.SupporterNumber}
      />
      <FormField
        element="Supporter"
        tree1="SupporterName"
        fldId="First"
        label="First Name"
        handleFldChange={handleFldChange}
        disabledState={disabledState || supporterObj?.SupporterNumber}
        fldValue={
          supporterObj?.SupporterName && supporterObj?.SupporterName.First
        }
      />
      <FormField
        element="Supporter"
        tree1="SupporterName"
        fldId="Last"
        label="Last Name"
        handleFldChange={handleFldChange}
        disabledState={disabledState || supporterObj?.SupporterNumber}
        fldValue={supporterObj?.SupporterName && supporterObj?.SupporterName.Last}
      />
      <FormField
        fldId="EmailAddress"
        label="Email"
        element="Supporter"
        handleFldChange={handleFldChange}
        disabledState={disabledState || supporterObj?.SupporterNumber}
        fldValue={supporterObj && supporterObj?.EmailAddress}
      />
      <FormField
        fldId="Phone"
        label="Phone"
        element="Supporter"
        disabledState={disabledState || supporterObj.SupporterNumber}
        handleFldChange={handleFldChange}
        fldValue={supporterObj && supporterObj.Phone}
      />
      <FormField
        fldId="PhoneType"
        label="Phone Type"
        element="Supporter"
        disabledState={disabledState || supporterObj.SupporterNumber}
        handleFldChange={handleFldChange}
        fldValue={supporterObj && supporterObj.PhoneType}
      />
      <fieldset>
        <h5>Address</h5>
        <FormField
          fldId="AddressLine1"
          label="Address LIne 1"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj?.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={supporterObj?.Address && supporterObj?.Address?.AddressLine1}
        />
        <FormField
          fldId="AddressLine2"
          label="Address LIne 2"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj?.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={supporterObj?.Address && supporterObj?.Address?.AddressLine2}
        />
        <FormField
          fldId="City"
          label="City"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj?.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={supporterObj?.Address && supporterObj?.Address.City}
        />
        <FormField
          fldId="StateOrProvince"
          label="State / Province"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={
            supporterObj?.Address && supporterObj?.Address?.StateOrProvince
          }
        />
        <FormField
          fldId="PostalCode"
          label="Zip / Postal Code"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={supporterObj?.Address && supporterObj?.Address?.PostalCode}
        />
        <FormField
          fldId="Country"
          label="Country"
          element="Supporter"
          tree1="Address"
          disabledState={disabledState || supporterObj?.SupporterNumber}
          handleFldChange={handleFldChange}
          fldValue={supporterObj?.Address && supporterObj?.Address?.Country}
        />
      </fieldset>
      {supporterObj?.Preferences && (
        <>
          <fieldset>
            <h5>Preferences</h5>
            <FormField
              fldId="DoNotEmail"
              label="Do Not Email"
              element="Supporter"
              tree1="Preferences"
              disabledState={disabledState || supporterObj.SupporterNumber}
              handleFldChange={handleFldChange}
              fldValue={
                supporterObj.Preferences && supporterObj.Preferences.DoNotEmail
              }
            />
            <FormField
              fldId="DoNotPhone"
              label="Do Not Phone"
              element="Supporter"
              tree1="Preferences"
              disabledState={disabledState || supporterObj.SupporterNumber}
              handleFldChange={handleFldChange}
              fldValue={
                supporterObj.Preferences && supporterObj.Preferences.DoNotPhone
              }
            />
            <FormField
              fldId="DoNotPostalMail"
              label="Do Not Postal Mail"
              element="Supporter"
              tree1="Preferences"
              disabledState={disabledState || supporterObj.SupporterNumber}
              handleFldChange={handleFldChange}
              fldValue={
                supporterObj.Preferences &&
                supporterObj.Preferences.DoNotPostalMail
              }
            />
            <FormField
              fldId="DoNotSMS"
              label="Do Not SMS"
              element="Supporter"
              tree1="Preferences"
              disabledState={disabledState || supporterObj.SupporterNumber}
              handleFldChange={handleFldChange}
              fldValue={
                supporterObj.Preferences && supporterObj.Preferences.DoNotSMS
              }
            />
          </fieldset>
        </>
      )}
    </>
  );
};
