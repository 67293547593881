import "./App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { PageLayout } from "../Layout/PageLayout";
import { AnnonPageLayout } from "../Layout/AnnonPageLayout";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div id="app" className="d-flex flex-column h-100">
      <Router>
        <AuthenticatedTemplate>
          <PageLayout />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AnnonPageLayout />
        </UnauthenticatedTemplate>
      </Router>
    </div>
  );
}

export default App;
