import { msalInstance } from "../index";

// retrieve the configs from the env variables
const ad_authority = process.env.REACT_APP_AD_AUTHORITY;
const ist_authority = process.env.REACT_APP_IST_API_AUTHORITY;

/**
 * Retrieve the access token to connect to the services endpoint
*/
export const getAccessToken = async () => {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];

    const request = {
        authority: ad_authority,
        scopes: [ist_authority],
        account: account,
    };

    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (e) {
        msalInstance.acquireTokenPopup(request).then((response) => {
        console.log("Token error: " + response);
        });
    }
    return null;
}