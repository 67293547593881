import React from "react";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Instance } from "../Instance/Instance";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from "react-promise-tracker";
import { Col, Card, Form, Row, Container, Button } from "react-bootstrap";
//import './search.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
//import { getAccessToken, getOrderByRefNum } from "./searchHelper";

export const SearchApp = () => {
  //set state variable; "Values" stores search field inputs, other types of data and search inputs have their own state, as recommended by Ray
  const { instance, accounts } = useMsal();
  const [instances, setInstances] = useState([]);
  const [instances_meta, setInstancesMeta] = useState([]);
  const [defaultDate, setdefaultDate] = useState(false);
  // datepicker state objects
  const [startDate, setStartDate] = useState({ Date: "" });
  const [endDate, setEndDate] = useState({ Date: "" });
  const [OrderData, setOrderData] = useState({
    OrderReferenceNumber: "",
    SupporterNumber: "",
    OrderDate: "",
  });
  const [values, setValues] = React.useState({
    orderRef: "",
    User: "",
    Status: "Both",
    orderQueue: "None",
  });
  // setter for value inputs
  const set = (name) => {
    return ({ target: { value } }) => {
      setValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };
  //checkbox state objects
  const [isDCSChecked, setDCSIsChecked] = useState(false);
  const [isClosedChecked, setClosedIsChecked] = useState(false);
  //search message
  const [Message, setMessage] = useState("");

  //checkbox handler functions
  function HandleClosedCheck() {
    setClosedIsChecked(!isClosedChecked);
  }

  function HandleDCSCheck() {
    setDCSIsChecked(!isDCSChecked);
  }

  //wrapper for handlesubmit; facilitates search interstitial
  async function retrieveSearchResults(event) {
    event.preventDefault(); // Prevent default submission
    document.body.style.opacity = ".5";
    await trackPromise(handleSubmit());
    document.body.style.opacity = "";
  }

  async function clearInputs(event) {
    event.preventDefault(); // Prevent default submission
    setMessage("");
    setOrderData({
      SupporterNumber: "",
      OrderDate: "",
      OrderReferenceNumber: "",
    });
    setClosedIsChecked(false);
    setdefaultDate(false);
    setDCSIsChecked(false);
    setStartDate({ Date: "" });
    setEndDate({ Date: "" });
    setValues({
      orderRef: "",
      User: "",
      Status: "Both",
      orderQueue: "None",
    });
    document.getElementById("orderRef").value = "";
    setInstances([]);
  }
  //form submission handler function
  async function handleSubmit() {
    // setInstances();
    //empty non-form states on submission
    setMessage("");
    setOrderData({
      SupporterNumber: "",
      OrderDate: "",
      OrderReferenceNumber: "",
    });
    setdefaultDate(false);
    //setup empty arrays
    let searchResultInstances = [];
    let searchResultCriteriaInstances = [];
    let criteriaResults = [];
    let orderQueue = "";
    let orderDetailsByReference = [];
    let formattedResults = "";

    //if values.orderRef exists do exclusive search by order ref and display order data as result
    if (values.orderRef) {
      orderDetailsByReference = await getOrderByRefNum(values.orderRef);
      setOrderData({
        SupporterNumber: orderDetailsByReference.SupporterNumber,
        OrderDate: orderDetailsByReference.OrderDate,
        OrderReferenceNumber: orderDetailsByReference.OrderReferenceNumber,
        OrderNumber: orderDetailsByReference.OrderNumber,
        CampaignSourceCode: orderDetailsByReference.CampaignSourceCode,
      });
      //if order record not found show appropriate messaging
      if (
        orderDetailsByReference.Message &&
        orderDetailsByReference.Message.includes("Record not found")
      ) {
        setMessage("No Order Found with this order reference number");
        ScrollTo("anchor");
      }
      //empty instances and return to end execution now that we have order data
      setInstances();
      return;
    }
    //determine orderQueue value from checkbox field state
    isClosedChecked && isDCSChecked
      ? (orderQueue = "Both")
      : isClosedChecked
      ? (orderQueue = "CLOSED")
      : isDCSChecked
      ? (orderQueue = "DCS")
      : (orderQueue = "");

    // if user or OrderQueue present, call getInstancesByUser API (which also takes orderQueue argument)
    if (values.User || (orderQueue && orderQueue !== "")) {
      // if no orderQueue field is set then set false by default
      orderQueue = orderQueue !== "" ? orderQueue : false;

      const resp = await GetInstancesByUser(values.User, orderQueue);
      for (var i = 0; i < resp.length; i++) {
        //populate searchResultInstances array with API results
        searchResultInstances.push(resp[i]);
      }
    }
    //if we have a status or start/end date we need to use SVC ListInstancesByCriteria endpoint
    if (
      (values.Status && values.Status !== "Both") ||
      startDate.Date ||
      endDate.Date
    ) {
      let StartDate = startDate.Date;
      let EndDate = endDate.Date;
      // default startDate to 30 days ago if open ended status search
      if (values.Status && !startDate.Date) {
        var priordate = new Date();
        var beforedate = new Date();
        priordate.setDate(beforedate.getDate() - 30);
        StartDate = priordate;
        setdefaultDate(true);
      }

      //call SVC for list of instances
      const criteriaResp = await ListInstancesByCriteria(
        StartDate,
        EndDate,
        values.Status
      );
      criteriaResults = criteriaResp.Results;
      //populate searchResultCriteriaInstances array with instance Ids from svc call
      for (var m = 0; m < criteriaResp.Results.length; m++) {
        searchResultCriteriaInstances.push(criteriaResp.Results[m].InstanceId);
      }
    }
    // compare search results arrays and merge to generate final search results
    if (
      (searchResultCriteriaInstances.length ||
        (values.Status && values.Status !== "Both") ||
        startDate.Date ||
        endDate.Date) &&
      (searchResultInstances.length || values.User)
    ) {
      //array merging function for inclusive results
      const mergeArrays = function (element) {
        return searchResultInstances.includes(element.InstanceId);
      };

      const searchResultsIntersection = criteriaResults.filter(mergeArrays);
      //check if we have anything in our merged array
      if (searchResultsIntersection.length >= 1) {
        await FormatSearchResults(searchResultsIntersection);
      }
      //if not inclusive search had no results, so we have nothing to show, but we need to resolve the promise for our interstitial
      else {
        setInstances();
        return Promise.resolve(formattedResults);
      }
    }
    // if we have no results from instances by criteria service, we call the read service on each specific instance form meta data
    else if (
      !searchResultCriteriaInstances.length &&
      searchResultInstances.length
    ) {
      let instanceStatusArr = [];
      for (var r = 0; r < searchResultInstances.length; r++) {
        var statusResp = await ReadStatusforFnF(searchResultInstances[r]);
        if (!statusResp.Message || !statusResp.Message.includes("not found")) {
          instanceStatusArr.push(statusResp);
        }
      }

      instanceStatusArr = instanceStatusArr.sort(
        (a, b) => moment(a.CreatedTime).unix() - moment(b.CreatedTime).unix()
      );

      await FormatSearchResults(instanceStatusArr);
    }
    // if no need to be inclusive w/ metadata, user only criteria results and lookup their metadata
    else if (
      searchResultCriteriaInstances.length &&
      !searchResultInstances.length
    ) {
      await FormatSearchResults(criteriaResults);
      return Promise.resolve(formattedResults);
    }
    // no matching parameters; show appropriate messaging
    else {
      setInstances();
      if (!startDate.Date & !endDate.Date) {
        setMessage("You must provide search parameters");
      }
    }
    //scroll to results
    ScrollTo("anchor");

    //fulfill promise
    return Promise.resolve(formattedResults);
  }

  //Handle "ScrollTo" functionality to show results in window
  function ScrollTo(name) {
    ScrollToResolver(document.getElementById(name));
  }

  function ScrollToResolver(elem) {
    var jump = parseInt(elem.getBoundingClientRect().top * 0.2);
    document.body.scrollTop += jump;
    document.documentElement.scrollTop += jump;
    if (!elem.lastjump || elem.lastjump > Math.abs(jump)) {
      elem.lastjump = Math.abs(jump);
      setTimeout(function () {
        ScrollToResolver(elem);
      }, "100");
    } else {
      elem.lastjump = null;
    }
  }

  async function getAccessToken() {
    const request = {
      authority: process.env.REACT_APP_AD_AUTHORITY,
      scopes: [process.env.REACT_APP_IST_API_AUTHORITY],
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (e) {
      instance.acquireTokenPopup(request).then((response) => {
        console.log("TokenPopup: " + response);
      });
    }
    return null;
  }

  async function getOrderByRefNum(orderRefNumber) {
    const tokenNew = await getAccessToken();

    if (tokenNew) {
      const headers = {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY,
        Authorization: "Bearer " + tokenNew,
      };

      const res = await fetch(
        `${process.env.REACT_APP_IST_API_IDEA_URL}/v1/orders/${orderRefNumber}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const json = await res.json();
      return json;
    }
  }
  async function ReadStatusforFnF(InstanceId) {
    const tokenNew = await getAccessToken();

    if (tokenNew) {
      const headers = {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY,
        Authorization: "Bearer " + tokenNew,
      };

      // get the 30 days ago date
      const res = await fetch(
        `${process.env.REACT_APP_IST_API_INSTANCES_URL}/v1/instances/${InstanceId}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const json = await res.json();
      return json;
    }
  }
  async function GetInstancesByUser(User, orderQueue) {
    const tokenNew = await getAccessToken();

    if (tokenNew) {
      const headers = {
        "x-functions-key": process.env.REACT_APP_META_API_FUNCTIONS_KEY,
      };

      const res = await fetch(
        `${process.env.REACT_APP_META_API_URL}?orderQueue=${orderQueue}&User=${User}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const json = await res.json();
      return json;
    }
  }

  async function getInstancesMeta(ids_arr) {
    const headers = {
      "x-functions-key": process.env.REACT_APP_META_API_FUNCTIONS_KEY,
    };

    const instances_csv = ids_arr.join(",");
    var body = '{ "Instances": "' + instances_csv + '" }';
    console.log("body", body);

    // calling the metadata api
    const meta = await fetch(`${process.env.REACT_APP_META_API_URL}?`, {
      method: "POST",
      headers: headers,
      body: body,
    });
    const result = await meta.json();

    let instances_meta_arr = [];
    result.forEach((instance) => {
      if (instance) {
        instances_meta_arr[instance.RowKey] = instance;
      }
    });

    return instances_meta_arr;
  }
  //normalize date function to help convert data type from react datepicker
  function normalizeDate(date) {
    var normalizedDate = date.toJSON().substring(0, 10).replace("T", " ");
    return normalizedDate;
  }

  async function ListInstancesByCriteria(StartDate, EndDate, Status) {
    StartDate = StartDate ? normalizeDate(StartDate) : "";
    EndDate = EndDate ? normalizeDate(EndDate) : "";
    const tokenNew = await getAccessToken();

    if (tokenNew) {
      const headers = {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY,
        Authorization: "Bearer " + tokenNew,
      };

      const res = await fetch(
        `${process.env.REACT_APP_IST_API_INSTANCES_URL}/v1/instances/?instanceIdPrefix=cco&fromTime=${StartDate}&toTime=${EndDate}&Status=${Status}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const json = await res.json();
      return json;
    }
  }

  async function FormatSearchResults(instancesArray) {
    let p,
      j,
      rowItems = [],
      chunk = 4;
    for (p = 0, j = instancesArray.length; p < j; p += chunk) {
      rowItems[p] = instancesArray.slice(p, p + chunk);
    }

    let instanceIdsArr = [];
    instancesArray.forEach((instance) => {
      instanceIdsArr.push(instance.InstanceId);
    });
    if (instanceIdsArr.length > 0) {
      const instances_meta_arr = await getInstancesMeta(instanceIdsArr);
      setInstancesMeta(instances_meta_arr);
    } else console.log("no instances");

    if (!rowItems[0]) {
      return Promise.resolve(setInstances());
    }

    return Promise.resolve(setInstances(rowItems));
  }

  const LoadingIndicator = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress && (
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0.5,
          }}
        >
          <div className="row">
            <div className="w-50 mx-auto">
              <div className="spinner--wv-star mb-0 mt-2">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Container className="border rounded sg-swatch--wv-orange-40 bg-light p-4 col-10">
          <Form onSubmit={retrieveSearchResults}>
            <div className="row">
              <div className="form-group col-5">
                <label>
                  Checkout User{" "}
                  <small>
                    (full email address: e.g. email@worldvision.org)
                  </small>
                </label>
                <Form.Control
                  className={"input-sm col-10"}
                  defaultValue={values.User || ""}
                  onChange={set("User")}
                />
              </div>
              <div className="form-group col-3">&nbsp;</div>
              <div className="form-group col-4 text-right">
                <Form.Label className="label">
                  Order Reference Number{" "}
                  <small>(this will override other search param)</small>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="orderRef"
                  className={"input-sm col-10 float-right"}
                  defaultValue={values.orderRef}
                  onChange={set("orderRef")}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="form-group col-4">
                <label className={"mr-3"}>Instance Queue: </label>
                <input
                  type="checkbox"
                  checked={isDCSChecked}
                  onChange={HandleDCSCheck}
                />
                <label className={"ml-2 mr-4"}>DCS </label>
                <input
                  type="checkbox"
                  checked={isClosedChecked}
                  onChange={HandleClosedCheck}
                />
                <label className={"ml-2"}>CLOSED </label>
              </div>
              <div className="form-group col-5">
                <div className="row">
                  <div className="col-6">
                    <label>Start Date: </label>
                    <DatePicker
                      className={"input-sm col-10 form-control ml-3"}
                      selected={startDate.Date}
                      onChange={(date) => setStartDate({ Date: date })}
                      name="startDate"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div className="col-6">
                    <label>End Date: </label>
                    <DatePicker
                      className={"input-sm col-10 form-control ml-3"}
                      selected={endDate.Date}
                      onChange={(date) => setEndDate({ Date: date })}
                      name="endDate"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-3 text-right">
                <label>Instance Status: </label>
                <select
                  value={values.Status}
                  onChange={set("Status")}
                  className=" btn giving-form__btn--dropdown widget-product input-sm p-0 border ml-3"
                >
                  <option value="Completed">Completed</option>
                  <option value="Failed">Failed</option>
                  <option value="Both">Both</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <div className="form-group col-12 text-center">
                <Button
                  size="sm"
                  key="submit-search"
                  variant="outline-secondary"
                  className="btn-primary btn-small"
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  size="sm"
                  key="reset"
                  variant="outline-secondary"
                  className="btn-ghost-orange btn-small ml-4"
                  type="submit"
                  onClick={clearInputs}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        </Container>
      </Row>
      <div>
        <LoadingIndicator />
        <p className="search-results" id="anchor">
          {defaultDate ? <div>Last 30 Days</div> : <></>} {Message}
        </p>
      </div>

      {!instances && !OrderData.OrderReferenceNumber && !values.orderRef ? (
        <h3>No Instances in the system</h3>
      ) : !instances && !OrderData.OrderReferenceNumber && values.orderRef ? (
        <></>
      ) : OrderData.OrderReferenceNumber ? (
        <>
          <h3>Order Number is: {OrderData.OrderReferenceNumber}</h3>

          <Row className="mt-5 mb-5">
            {
              <Col md="3">
                <Card>
                  <Card.Header>{OrderData.OrderReferenceNumber}</Card.Header>
                  <Card.Body>
                    <Card.Text className="mb-1">
                      Supporter: {OrderData.SupporterNumber}
                    </Card.Text>
                    <Card.Text className="mb-1">
                      Date: {OrderData.OrderDate}
                    </Card.Text>
                    <Card.Text className="mb-1">
                      OrderNumber: {OrderData.OrderNumber}
                    </Card.Text>
                    <Card.Text className="mb-1">
                      CampaignSourceCode: {OrderData.CampaignSourceCode}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            }
          </Row>
        </>
      ) : (
        instances.map((row, i) => (
          <Row key={i} className="mt-5 mb-5">
            {row.map((inst, x) => (
              <Instance
                id={inst.InstanceId}
                key={inst.InstanceId}
                instance={inst}
                meta={instances_meta[inst.InstanceId]}
              />
            ))}
          </Row>
        ))
      )}
    </>
  );
};
