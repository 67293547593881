import React from "react";
import { useMsal } from "@azure/msal-react";

function handleLogout(instance) {
  instance.logoutPopup().catch((e) => {
    console.log(e);
  });
}

export const SignOutButton = () => {
  const { instance } = useMsal();

  return (
    <>
      <button
        className="btn btn-text btn-text-orange small"
        onClick={() => handleLogout(instance)}
      >
        Log Out
      </button>
    </>
  );
};
