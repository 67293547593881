import { FormField } from "./FormField";

export const CommitmentLineItem = ({
  itemCntr,
  item,
  handleFldChange,
  disabledState,
}) => {
  return (
    <>
      <FormField
        itemCntr={itemCntr}
        fldId="ItemNumber"
        label="Item Number"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.ItemNumber}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="CommitmentType"
        label="Commitment Type"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.CommitmentType}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="CommitmentReferenceNumber"
        label="Commitment Reference Number"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.CommitmentReferenceNumber}
      />
      {item.CommitmentType === "Sponsorship" && (
        <>
          <FormField
            itemCntr={itemCntr}
            fldId="ChildNumber"
            label="Child Number"
            element="CommitmentLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={item.ChildNumber}
          />
          <FormField
            itemCntr={itemCntr}
            fldId="ChildDescription"
            label="Child Description"
            element="CommitmentLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={item.ChildDescription}
          />
          <FormField
            itemCntr={itemCntr}
            fldId="SupportOfficeChildNumber"
            label="Support Office Child Number"
            element="CommitmentLines"
            tree1={itemCntr}
            disabledState={disabledState}
            handleFldChange={handleFldChange}
            fldValue={item.SupportOfficeChildNumber}
          />
        </>
      )}
      <FormField
        itemCntr={itemCntr}
        fldId="PaymentRate"
        label="Payment Rate"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.PaymentRate}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="Quantity"
        label="Quantity"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.Quantity}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="PaymentDrawDay"
        label="Payment Draw Day"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.PaymentDrawDay}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="PaymentFrequency"
        label="Payment Frequency"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.PaymentFrequency}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="EventNumber"
        label="Event Number"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.EventNumber}
      />
      <FormField
        itemCntr={itemCntr}
        fldId="EventDonorResource"
        label="Event Donor Resource"
        element="CommitmentLines"
        tree1={itemCntr}
        disabledState={disabledState}
        handleFldChange={handleFldChange}
        fldValue={item.EventDonorResource}
      />
    </>
  );
};
