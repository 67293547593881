import { useMsal } from "@azure/msal-react";
import {
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Nav,
  Button,
  Container,
  Card,
  Badge,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommitmentLineItem } from "./CommitmentLineItem";
import { OrderLineItem } from "./OrderLineItem";
import { FormField } from "./FormField";
import { SupporterForm } from "./SupporterForm";
import Moment from "react-moment";

export const Details = () => {
  const { instance, accounts } = useMsal();
  const [key, setKey] = useState("supporter");
  const [formDsbld, setFormDsbld] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [spinner, setSpinner] = useState(true);
  const [warningMsg, setWarningMsg] = useState(null);
  const [instanceObj, setInstanceObj] = useState({});
  const [inputData, setInputData] = useState({});
  const [supporterObj, setSupporterObj] = useState({});
  const [paymentMethodObj, setPaymentMethodObj] = useState(null);
  const [creditCardObj, setCreditCardObj] = useState({});
  const [eftObj, seteftObj] = useState({});
  const [orderItems, setOrderItems] = useState([]);
  const [commitmentItems, setCommitmentItems] = useState([]);
  const [instanceMeta, setInstanceMeta] = useState({});
  const [errorInfo, setErrorInfo] = useState([]);
  const [failedHistoryItem, setFailedHistoryItem] = useState({});
  const [instanceStatus, setInstanceStatus] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    requestInstance(id);
  }, []); // eslint-disable-line

  /**
   * Retrieve the access token to connect to the services endpoint
   */
  async function getAccessToken() {
    const request = {
      authority: process.env.REACT_APP_AD_AUTHORITY,
      scopes: [process.env.REACT_APP_IST_API_AUTHORITY],
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      const accessToken = response.accessToken;
      setAccessToken(accessToken);
      return accessToken;
    } catch (e) {
      instance.acquireTokenPopup(request).then((response) => {
        console.log("TokenPopup: " + response);
      });
    }
    return null;
  }

  /**
   * Retrieve the instance data from the API
   */
  async function getInstanceData(instace_id) {
    const tokenNew = await getAccessToken();

    if (tokenNew) {
      const headers = {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY,
        Authorization: "Bearer " + tokenNew,
      };

      const res = await fetch(
        `${process.env.REACT_APP_IST_API_INSTANCES_URL}/v1/instances/${instace_id}?showSimplifiedHistory=true&showHistory=true`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const instance = await res.json();
      return instance;
    }
    return null;
  }

  /**
   * Process the retrieved instance data
   * @param {string} id
   */
  async function requestInstance(id) {
    // load the instance data”
    const instance = await getInstanceData(id);
    if (instance.Input) {
      setInstanceObj(instance);
      setInputData(instance.Input.Item2);
      setSupporterObj(instance.Input?.Item2?.Supporter);

      if (instance.Input?.Item2?.PaymentMethod) {
        setPaymentMethodObj(instance.Input?.Item2.PaymentMethod);
      }
      setCreditCardObj(instance.Input?.Item2?.CreditCard);
      seteftObj(instance.Input?.Item2?.EftAccount);
      setCommitmentItems(instance.Input?.Item2?.CommitmentLines);
      setOrderItems(instance.Input?.Item2?.OrderLines);
      setErrorInfo(instance.Output[0]);
      setFailedHistoryItem(instance.History[instance.History.length - 1]);
      setInstanceStatus(instance.RuntimeStatus);

      const meta = await loadInstanceMeta(instance.InstanceId);
      setInstanceMeta(meta);

      if (instanceMeta.User && instanceMeta.User.length > 1) {
        setWarningMsg(
          `User ${instanceMeta.User} is currently editing this instance`
        );
      } else if (instance.RuntimeStatus !== "Failed") {
        setWarningMsg(
          `Instance cannot be modified or submitted since it's not in the Failed state`
        );
      }
    }
  }

  /**
   * function to provision a default meta object
   */
  function provisionMetaObj() {
    // const curUser = accounts[0];
    const meta = {
      // User: curUser.username,
      User: '',
      Instance: instanceObj.InstanceId,
      OrderQueue: "false",
      RetrySuccess: "false",
      ResubmittedInstance: null,
      ResubmittedInstanceStatus: null,
    };
    setInstanceMeta(meta);
    return meta;
  }

  /**
   * Handling DCS checkbox in the Order Queues
   */
  async function handleDcsChck(val) {
    let metaObj = instanceMeta;

    if (!instanceMeta.RowKey) {
      metaObj = provisionMetaObj();
    } else {
      metaObj.Instance = instanceMeta.RowKey;
    }

    if (val.target.checked === true) {
      if (metaObj.OrderQueue === "CLOSED") {
        metaObj.OrderQueue = "DCSCLOSED";
      } else {
        metaObj.OrderQueue = "DCS";
      }
    } else {
      if (
        metaObj.OrderQueue === "CLOSED" ||
        metaObj.OrderQueue === "DCSCLOSED"
      ) {
        metaObj.OrderQueue = "CLOSED";
      } else {
        metaObj.OrderQueue = "false";
      }
    }

    setSpinner(true);
    await metdataApiUpdateInstance(metaObj);
    setSpinner(false);
  }

  /**
   * Handle the Closed checkbox
   */
  async function handleClsdChck(val) {
    let metaObj = instanceMeta;
    if (!instanceMeta.RowKey) {
      metaObj = provisionMetaObj();
    }

    if (val.target.checked === true) {
      if (metaObj.OrderQueue === "DCS") {
        metaObj.OrderQueue = "DCSCLOSED";
      } else {
        metaObj.OrderQueue = "CLOSED";
      }
      metaObj.User = "";
    } else {
      if (metaObj.OrderQueue === "DCS" || metaObj.OrderQueue === "DCSCLOSED") {
        metaObj.OrderQueue = "DCS";
      } else {
        metaObj.OrderQueue = "false";
      }
    }

    setSpinner(true);
    await metdataApiUpdateInstance(metaObj);
    setSpinner(false);
  }

  /**
   * Generic Form Field change action handler function
   * this function is passed into each field to handle it's change action
   */
  const handleFldChange = (val) => {
    let inputObj = inputData;

    const fldVal = val.target.value;
    const fldName = val.target.name;
    const dataTree1 = val.target.dataset.tree1;
    const dataElement = val.target.dataset.element;

    setFormDsbld(false);

    if (!dataElement) {
      inputObj[fldName] = fldVal;
    }

    if (dataElement && !dataTree1) {
      inputObj[dataElement][fldName] = fldVal;
    }

    if (dataElement && dataTree1) {
      inputObj[dataElement][dataTree1][fldName] = fldVal;
    }

    setInputData(inputObj);
  };

  function handleFetchErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  /**
   * Function to submit the instance object using the new data
   * @param {*} val
   */
  async function submitInstance(val) {
    setSpinner(true);
    if (!formDsbld) {
      const headers = {
        "Ocp-Apim-Subscription-Key":
          process.env.REACT_APP_IST_API_SUBSCRIPTION_KEY,
        Authorization: "Bearer " + accessToken,
      };

      await fetch(
        `${process.env.REACT_APP_IST_API_INSTANCES_URL}/v1/instances/CreateCompositeOrder`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(inputData),
        }
      )
        .then(handleFetchErrors)
        .then(async (response) => {
          const resp_json = await response.json();

          let metaObj = instanceMeta;
          if (!instanceMeta.RowKey) {
            metaObj = provisionMetaObj();
          }

          if (resp_json && resp_json.InstanceId) {
            metaObj.ResubmittedInstance = resp_json.InstanceId;
          }

          // metaObj.OrderQueue = instanceMeta.OrderQueue === "DCS" ? "DCSCLOSED" : "CLOSED";
          metaObj.User = "";
          metdataApiUpdateInstance(metaObj);
          setWarningMsg("Order Resubmitted");
          setFormDsbld(true);
          console.log("ok");

          const timer = setInterval(async () => {
            const instanceData = await getInstanceData(
              metaObj.ResubmittedInstance
            );

            if (
              instanceData.RuntimeStatus &&
              instanceData.RuntimeStatus !== metaObj.ResubmittedInstanceStatus
            ) {
              metaObj.ResubmittedInstanceStatus = instanceData.RuntimeStatus;
              metdataApiUpdateInstance(metaObj);
            }

            // stop the calls if the status is Failed or Completed
            if (
              instanceData.RuntimeStatus === "Completed" ||
              instanceData.RuntimeStatus === "Failed"
            ) {
              clearTimeout(timer);
            }
          }, 2000);
        })
        .catch((error) => console.log(error));
    }
    setSpinner(false);
  }

  /**
   * Makes the call to the metadata API endpoint
   * to update or create new instance meta data
   * @param metaObj
   */
  async function metdataApiUpdateInstance(metaObj) {
    const headers = {
      "x-functions-key": process.env.REACT_APP_META_API_FUNCTIONS_KEY,
    };
    // calling the metadata api
    await fetch(process.env.REACT_APP_META_API_URL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(metaObj),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const meta = await loadInstanceMeta(instanceObj.InstanceId);
          setInstanceMeta(meta);

          if (instanceMeta.User && instanceMeta.User.length > 1) {
            setWarningMsg(
              `User ${meta.User} is currently editing this instance`
            );
          }
        }
        setSpinner(false);
      })
      .catch((error) => {
        console.log("HTTP error", error);
      });
  }

  /**
   * handling the start editing button action
   */
  async function startEditing() {
    const curUser = accounts[0];
    setFormDsbld(false);
    setSpinner(true);
    // console.log(curUser);
    // preprocessInputObj();

    inputData.OrderReferenceNumber =
      inputData?.OrderReferenceNumber +
      "-" +
      Math.floor(Math.random() * (10000 - 10) + 10);

    let meta = instanceMeta;
    if (!instanceMeta.Instance) {
      meta = {
        User: curUser.username,
        Instance: instanceObj.InstanceId,
        OrderQueue: "false",
        RetrySuccess: "false",
      };
    } else {
      meta.User = curUser.username;
    }

    setInstanceMeta(meta);
    await metdataApiUpdateInstance(meta);
  }

  /**
   * handling the sop editing button click
   */
  async function stopEditing() {
    let meta = instanceMeta;
    if (!instanceMeta.Instance) {
      meta = {
        User: "",
        Instance: instanceObj.InstanceId,
        OrderQueue: "false",
        RetrySuccess: "false",
      };
    } else {
      meta.User = "";
    }
    setInstanceMeta(meta);
    await metdataApiUpdateInstance(meta);

    setFormDsbld(true);
    setWarningMsg(null);
  }

  /**
   * Load the Instance Meta from the API
   * @param {string} instnce_id
   */
  async function loadInstanceMeta(instnce_id) {
    const headers = {
      "x-functions-key": process.env.REACT_APP_META_API_FUNCTIONS_KEY,
    };
    // calling the metadata api
    const meta = await fetch(
      `${process.env.REACT_APP_META_API_URL}?Instance=${instnce_id}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const metaObj = await meta.json();

    metaObj.Instance = metaObj.RowKey;
    setInstanceMeta(metaObj);

    if (metaObj.User && metaObj.User.length > 1) {
      setWarningMsg(`User ${metaObj.User} is currently editing this instance`);
    }

    // display the inability to resubmit the instance whe it was already resubmitted
    if (metaObj.ResubmittedInstance && metaObj.ResubmittedInstance.length > 1) {
      setWarningMsg(
        `This instance was already resubmitted and can not be modified or resubmitted.`
      );
    }
    setSpinner(false);
    return metaObj;
  }

  return (
    <>
      <Row className="justify-content-md-center">
        <Col md="5">
          {warningMsg && (
            <div className="alert alert-warning" role="alert">
              {warningMsg}
            </div>
          )}
          <Form>
            <Tabs
              defaultActiveKey="supporter"
              id="cco_tabs"
              className="mb-3"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="supporter" title="Supporter">
                <SupporterForm
                  supporterObj={supporterObj}
                  handleFldChange={handleFldChange}
                  disabledState={formDsbld}
                />
              </Tab>

              <Tab eventKey="payment_method" title="Payment">
                {paymentMethodObj && (
                  <>
                    <FormField
                      fldId="PaymentMethodType"
                      label="Payment Method Type"
                      element="PaymentMethod"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={
                        paymentMethodObj && paymentMethodObj.PaymentMethodType
                      }
                    />
                    <FormField
                      fldId="PaymentMethodNumber"
                      label="Payment Method Number"
                      element="PaymentMethod"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={
                        paymentMethodObj && paymentMethodObj.PaymentMethodNumber
                      }
                    />
                  </>
                )}

                {creditCardObj && (
                  <>
                    <FormField
                      fldId="CreditCardType"
                      label="Credit Card Type"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.CreditCardType}
                    />
                    <FormField
                      fldId="CreditCardToken"
                      label="Credit Card Token"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.CreditCardToken}
                    />
                    <FormField
                      fldId="CreditCardTokenType"
                      label="Credit Card Token Type"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.CreditCardTokenType}
                    />
                    <FormField
                      fldId="CreditCardExpirationMonth"
                      label="Credit Card Expiration Month"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.CreditCardExpirationMonth}
                    />
                    <FormField
                      fldId="CreditCardExpirationYear"
                      label="Credit Card Expiration Year"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.CreditCardExpirationYear}
                    />
                    <FormField
                      fldId="ErpLiteralCreditCardType"
                      label="Erp Literal Credit Card Type"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.ErpLiteralCreditCardType}
                    />
                    <FormField
                      fldId="PaymentMethod"
                      itemCntr="cc"
                      label="Payment Method"
                      element="CreditCard"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={creditCardObj.PaymentMethod}
                    />
                  </>
                )}

                {eftObj && (
                  <>
                    <FormField
                      fldId="EftAccountNumber"
                      label="Eft Account Number"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.EftAccountNumber}
                    />
                    <FormField
                      fldId="EftAccountType"
                      label="Eft Account Type"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.EftAccountType}
                    />
                    <FormField
                      fldId="EftAgreementChannel"
                      label="Eft Agreement Channel"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.EftAgreementChannel}
                    />
                    <FormField
                      fldId="EftRoutingNumber"
                      label="Eft Routing Number"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.EftRoutingNumber}
                    />
                    <FormField
                      fldId="EncryptedEftAccountNumber"
                      label="Encrypted EftAccount Number"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.EncryptedEftAccountNumber}
                    />
                    <FormField
                      fldId="PaymentMethod"
                      label="Payment Method"
                      element="EftAccount"
                      disabledState={formDsbld}
                      handleFldChange={handleFldChange}
                      fldValue={eftObj.PaymentMethod}
                    />
                  </>
                )}

                {inputData && inputData.DigitalWalletCard && (
                  <>
                    <FormField
                      fldId="CardType"
                      label="Card Type"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData.DigitalWalletCard.CardType}
                    />
                    <FormField
                      fldId="CardToken"
                      label="Card Token"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData.DigitalWalletCard.CardToken}
                    />
                    <FormField
                      fldId="ExpirationMonth"
                      label="Expiration Month"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData.DigitalWalletCard.ExpirationMonth}
                    />
                    <FormField
                      fldId="ExpirationYear"
                      label="Expiration Year"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData.DigitalWalletCard.ExpirationYear}
                    />
                    <FormField
                      fldId="PaymentMethod"
                      label="Payment Method"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData.DigitalWalletCard.PaymentMethod}
                    />
                    <FormField
                      fldId="SupporterNumber"
                      label="Supporter Number"
                      element="DigitalWalletCard"
                      disabledState={true}
                      fldValue={inputData?.DigitalWalletCard?.SupporterNumber}
                    />
                  </>
                )}
              </Tab>

              {/* ORDER ITEMS TAB */}
              {orderItems && (
                <Tab eventKey="order_items" title="Order Items">
                  <Tab.Container id="left-tabs-order" defaultActiveKey="tab0">
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          {orderItems.map((item, x) => (
                            <Nav.Item key={x}>
                              <Nav.Link eventKey={"tab" + x}>
                                Item {x + 1}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          {orderItems.map((item, x) => (
                            <Tab.Pane eventKey={"tab" + x} key={x}>
                              <OrderLineItem
                                key={x}
                                itemCntr={x}
                                item={item}
                                disabledState={formDsbld}
                                handleFldChange={handleFldChange}
                              />
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab>
              )}

              {/* COMMITMENT ITEMS TAB */}
              {commitmentItems && (
                <Tab eventKey="commitment_items" title="Commitments">
                  <Tab.Container
                    id="left-tabs-commitments"
                    defaultActiveKey="tab0"
                  >
                    <Row>
                      <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                          {commitmentItems.map((item, x) => (
                            <Nav.Item key={x}>
                              <Nav.Link eventKey={"tab" + x}>
                                Item {x + 1}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          {commitmentItems.map((item, x) => (
                            <Tab.Pane eventKey={"tab" + x} key={x}>
                              <CommitmentLineItem
                                key={x}
                                itemCntr={x}
                                item={item}
                                disabledState={formDsbld}
                                handleFldChange={handleFldChange}
                              />
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab>
              )}

              <Tab eventKey="applicatoin" title="Application">
                <FormField
                  fldId="OrderReferenceNumber"
                  label="Order Reference Number (read only)"
                  disabledState={true}
                  handleFldChange={handleFldChange}
                  fldValue={inputData && inputData.OrderReferenceNumber}
                />
                <FormField
                  fldId="CampaignSourceCode"
                  label="Campaign Source Code"
                  disabledState={formDsbld}
                  handleFldChange={handleFldChange}
                  fldValue={
                    instanceObj.Input &&
                    instanceObj.Input?.Item2?.CampaignSourceCode
                  }
                />
                <FormField
                  fldId="InboundSalesChannel"
                  label="Inbound Sales Channel (read only)"
                  disabledState={true}
                  handleFldChange={handleFldChange}
                  fldValue={
                    instanceObj.Input &&
                    instanceObj.Input?.Item2?.InboundSalesChannel
                  }
                />
              </Tab>
              {instanceStatus === "Failed" && (
                <Tab eventKey="error_stack_trace" title="Error Info">
                  <Row>
                    <Col className={"pt-4"}>
                      <strong className={"mr-3"}>Failed Function: </strong>{" "}
                      {failedHistoryItem.FunctionName}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"pt-3"}>
                      <strong className={"mr-3"}>Failed Time: </strong>
                      <Moment format="DD-MMM-YYYY HH:mma">
                        {failedHistoryItem.Timestamp}
                      </Moment>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"pt-3"}>
                      <strong className={"mr-3"}>Summary: </strong>
                      <Card className={"border-0 text-danger"}>
                        {failedHistoryItem.Reason}
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"pt-3"}>
                      <strong className={"mr-3"}>Stack Trace: </strong>
                      <Card className={"border-0 text-danger"}>
                        {failedHistoryItem.Details}
                      </Card>
                    </Col>
                  </Row>
                  {/* {history && history.map((hist_itm) => <>Stack Trace</>)} */}
                </Tab>
              )}
            </Tabs>
          </Form>
        </Col>
        <Col md="3" className="ml-3 pt-5">
          <Container className="mb-5">
            {spinner === true ? (
              <div className="spinner--wv-star m-0">
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
              </div>
            ) : formDsbld === true ? (
              instanceStatus === "Failed" &&
              instanceMeta.OrderQueue !== "CLOSED" &&
              instanceMeta.OrderQueue !== "DCSCLOSED" &&
              (inputData?.OrderReferenceNumber && (!instanceMeta.ResubmittedInstance ||
                instanceMeta.ResubmittedInstance.length < 1)) && (
                <Button
                  size="sm"
                  key="start-edit"
                  variant="outline-secondary"
                  className="btn-ghost-orange btn-small"
                  onClick={(e) => {
                    e.preventDefault();
                    startEditing();
                  }}
                >
                  Start Editing
                </Button>
              )
            ) : (
              <>
                <Button
                  size="sm"
                  key="stop-editing"
                  variant="outline-secondary"
                  className="btn-ghost-orange btn-small mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    stopEditing();
                  }}
                >
                  Stop Editing
                </Button>
                <Button
                  size="sm"
                  key="resubmit-btn"
                  variant="outline-secondary"
                  className="btn-ghost-orange btn-small"
                  onClick={(e) => {
                    e.preventDefault();
                    submitInstance();
                  }}
                >
                  Resubmit
                </Button>
              </>
            )}
          </Container>
          <Container className="border">
            <Row>
              <Col>
                <h5>Instance Queues</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mb-3">
                  <Form.Check type="checkbox" className="pl-0">
                    <Form.Check.Input
                      type="checkbox"
                      onChange={handleDcsChck}
                      checked={
                        instanceMeta.OrderQueue === "DCS" ||
                        instanceMeta.OrderQueue === "DCSCLOSED"
                      }
                    />
                    <Form.Check.Label className="ml-4 pl-2">
                      DCS <small>Order that needs to have DCS follow up</small>
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type="checkbox" className="pl-0">
                    <Form.Check.Input
                      type="checkbox"
                      onChange={handleClsdChck}
                      checked={
                        instanceMeta.OrderQueue === "CLOSED" ||
                        instanceMeta.OrderQueue === "DCSCLOSED"
                      }
                    />
                    <Form.Check.Label className="ml-4 pl-2">
                      Closed
                      <small>
                        &nbsp;Order should not be processed from this console
                      </small>
                    </Form.Check.Label>
                  </Form.Check>
                </div>
              </Col>
            </Row>
          </Container>
          {instanceStatus === "Failed" && (
            <Container className="border mt-5">
              <Row>
                <Col>
                  <h5>Error Info</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <div className="mb-3">
                      <strong>Activity: </strong>
                      <div>{errorInfo.Activity}</div>
                    </div>
                    <div>
                      <strong>Message: </strong>
                      <div>{errorInfo.Message}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {instanceMeta.ResubmittedInstance && (
            <Container className="border mt-5">
              <Row>
                <Col>
                  <div className="mb-3">
                    <div className="mb-3 mt-3">
                      <strong>Resubmitted Instance: </strong>
                      <div>
                        {instanceMeta.ResubmittedInstanceStatus === "Failed" ? (
                          <>
                            <a
                              href={`/details/${instanceMeta.ResubmittedInstance}`}
                            >
                              {instanceMeta.ResubmittedInstance}
                            </a>
                          </>
                        ) : (
                          instanceMeta.ResubmittedInstance
                        )}
                        {instanceMeta.ResubmittedInstanceStatus === "Failed" ? (
                          <Badge
                            pill
                            bg="info"
                            className={"ml-2 mb-1"}
                            style={{
                              color: "white",
                              backgroundColor: "#dc3545",
                            }}
                          >
                            FAILED
                          </Badge>
                        ) : (
                          instanceMeta.ResubmittedInstanceStatus ===
                            "Completed" && (
                            <Badge
                              pill
                              bg="info"
                              className={"ml-2 mb-1"}
                              style={{
                                color: "white",
                                backgroundColor: "#28a745",
                              }}
                            >
                              SUCCESS
                            </Badge>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}

          {!supporterObj?.EmailAddress &&
            instanceObj.History &&
            instanceObj.History.length > 1 && (
              <Container className="border mt-5">
                <Row>
                  <Col>
                    <h5>Supporter Info</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <div className="mb-3">
                        <strong>Supporter Number: </strong>
                        <div>
                          {instanceObj?.History[0]?.Result?.SupporterNumber}
                        </div>
                      </div>
                      <div className="mb-3">
                        <strong>Email: </strong>
                        <div>
                          {instanceObj.History[0]?.Result?.EmailAddress}
                        </div>
                      </div>
                      <div className="mb-3">
                        <strong>Phone: </strong>
                        <div>{instanceObj.History[0]?.Result?.Phone}</div>
                      </div>
                      <div>
                        <strong>First & Last Name: </strong>
                        <div>
                          {instanceObj.History[0]?.Result?.SupporterName?.First}{" "}
                          {instanceObj.History[0]?.Result?.SupporterName?.Last}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
        </Col>
      </Row>
    </>
  );
};
