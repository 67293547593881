import { Col, Card, ButtonGroup, Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export const Instance = ({ id, instance, meta }) => {

  const OrderReferenceNumber =
    instance.Input?.Item2?.OrderReferenceNumber ?? "Not Available";

  // const order_nbr_raw = "1075040-1627492226-1627592354";
  // const order_nbr_raw = "1075044";

  const order_nbr = OrderReferenceNumber.split(/^(\d+)-?.*/g)[1] ?? null;
  // const order_nbr = order_nbr_raw.split(/^(\d+)-?.*/g)[1] ?? null;

  // console.log("order_nbr", order_nbr);
  // get the failed error messge and subfunction
  const err_msg = instance.Output[0]?.Message && instance.Output[0]?.Message;
  const err_fncn = instance.Output[0]?.Activity && instance.Output[0]?.Activity;

  return (
    <Col md="3" key={id}>
      <Card>
        <Card.Header>
          {OrderReferenceNumber}
          {meta !== undefined &&
            (meta.OrderQueue === "CLOSED" ||
              meta.OrderQueue === "DCSCLOSED") && (
              <Badge
                pill
                bg="info"
                className={"ml-2 mb-1"}
                style={{ color: "white", backgroundColor: "#dc3545" }}
              >
                CLOSED
              </Badge>
            )}
          {meta !== undefined &&
            (meta.OrderQueue === "DCS" || meta.OrderQueue === "DCSCLOSED") && (
              <Badge
                pill
                bg="info"
                className={"ml-2 mb-1"}
                style={{ backgroundColor: "#ffc107" }}
              >
                DCS
              </Badge>
            )}
        </Card.Header>
        <Card.Body>
          {err_fncn && <Card.Text className="mb-1">{err_fncn}</Card.Text>}
          <Card.Text className="mb-1">{instance.InstanceId}</Card.Text>
          <Card.Text className="mb-1">
            {order_nbr && (
              <>
                Order:{" "}
                <a
                  href={`https://donate.worldvision.org/wp-admin/post.php?post=${order_nbr}&action=edit`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {OrderReferenceNumber}
                </a>
              </>
            )}
          </Card.Text>
          <Card.Text className="mb-1">
            Failed time:{" "}
            <Moment format="DD-MMM-YYYY HH:mma">{instance.CreatedTime}</Moment>
          </Card.Text>
          <Card.Text className={"pt-1"}>
            Failure Message: <small className={"text-danger"}>{err_msg}</small>
          </Card.Text>
          {instance.Input?.Item2?.OrderReferenceNumber && (
            <ButtonGroup className={"float-right"}>
              <Link to={`/details/${id}`}>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="btn-ghost-orange btn-small"
                >
                  Details
                </Button>
              </Link>
            </ButtonGroup>
          ) }
        </Card.Body>
      </Card>
    </Col>
  );
};
