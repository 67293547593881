import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header>
      <Row className={"m-2"}>
        <Col md="4">
          <Link to="/">
            <div className="sg-logo-container">
              <svg
                className="sg-logo"
                xmlns="http://www.w3.org/2000/svg"
                height="69"
                viewBox="0 0 531.72 109.76"
              >
                <title>World Vision</title>
                <path
                  fill="#fff"
                  d="M481 5.26h43.5V88.2l-56.58-51.94 13.08-31z"
                />
                <path
                  d="M350.9.59a.31.31 0 01-.29-.3.29.29 0 01.29-.29h180.82v109.76A263.81 263.81 0 00350.9.59M501.88 64c1.22-18.07.83-28.62 3.92-31.49 2.86-2.65 8-3.37 16.78-4-19.24-2.22-18.77-1.65-20.7-20.35-1.94 18.7-1.47 18.13-20.71 20.35 8.76.64 13.92 1.36 16.78 4 3.09 2.87 2.7 13.42 3.93 31.49"
                  fill="#ff6b00"
                />
                <path
                  d="M121 68.67v39.07h10.8V76c3.64-3.12 6.24-2.08 13.41 3.84l4.36-9.84a21.53 21.53 0 00-9-2 11.2 11.2 0 00-8.57 3.79l-.52.62-.1-.1.1-3.64zM164.74 48h-10.81v59.74h10.81zM72.2 88.21c0 10.91 8.1 19.94 21.19 19.94s21.2-9 21.2-19.94-8.11-19.95-21.2-19.95S72.2 77.3 72.2 88.21m229.26-19.54h-10.8v39.07h10.8zm-4-17.65a5.71 5.71 0 105.71 5.71 5.63 5.63 0 00-5.76-5.73m10.78 28.36c-1 14.85 20.78 11.32 20.15 20.36-.31 3.63-3.43 4.88-6.75 4.78-5.4-.42-8.73-3.54-11.64-7l-3.21 7.17a32.13 32.13 0 0013.26 4.11c9.35.62 17.25-3.44 17.76-12.47.94-14-21.6-12.26-21.19-19.43.21-3.11 3.74-4.67 7.38-4.47s6.65 2.39 9.87 5.3l2.91-6.44a36.58 36.58 0 00-11.33-2.91c-10.07-.62-16.83 4.68-17.24 11m-117.48 29.21a20.48 20.48 0 0013-4.68l.2.11v3.74h10V48H203v23.79a21 21 0 00-12.25-3.74c-5.93 0-20 3.64-20 20.78 0 12.78 11.21 19.74 19.94 19.74m70.47-.83h4.58l22.85-52.47h-5.92l-15.9 36.88-15.69-36.88H238zm191.32 0V86.23c0-14.54-11.11-18.18-17.65-18.18-4.68 0-8.63 1.77-12.58 4.47l-.21-.11v-3.74h-10v39.07h10.8V76.26c3.22-2.81 6.34-4 9-4 5.72 0 9.66 5.51 9.66 11.53v23.9zM44.05 60.26h-6.13L25.66 92 12.57 55.48H0l19.43 52.15h5.92l12.57-32.2 12.46 32.2h5.93l19.42-52h-5.92L56.2 92zm59.11 27.95c0 9-3 16.2-9.77 16.2s-9.76-7.17-9.76-16.2S86.64 72 93.39 72s9.77 7.17 9.77 16.21m259.52 0c0 10.91 8.1 19.94 21.19 19.94s21.19-9 21.19-19.94-8.06-19.95-21.19-19.95-21.19 9-21.19 19.95m31 0c0 9-3 16.2-9.77 16.2s-9.76-7.17-9.76-16.2 3-16.21 9.76-16.21 9.77 7.17 9.77 16.21M203 76.47v23.58c-2.8 2.39-5.4 4.16-9.35 4.16-4.78 0-11-4.06-11-15.8 0-12.67 9.87-21.81 20.36-11.94M344.8 68.67h10.8v39.06h-10.8zM350.55 51a5.71 5.71 0 105.71 5.71 5.63 5.63 0 00-5.71-5.71M463.64 71.4a4.28 4.28 0 114.28-4.28 4.26 4.26 0 01-4.28 4.28m0-7.84a3.57 3.57 0 103.58 3.56 3.55 3.55 0 00-3.58-3.56m-1.86 1.34h2c1.13 0 1.52.7 1.52 1.2a1.3 1.3 0 01-1.3 1.32c.39.14.68.53 1.06 1.14l.56.9h-.91l-.4-.72c-.58-1-.8-1.23-1.37-1.23h-.44v1.95h-.74zm1.81 2c.57 0 1-.22 1-.69s-.34-.69-.82-.69h-1.2v1.38z"
                  fill="#231f20"
                />
              </svg>
            </div>
          </Link>
        </Col>
        <Col md="5" className={"text-center"}>
          <h1>COS Administration</h1>
        </Col>
        <Col md="3" className={"text-right"}></Col>
      </Row>
    </header>
  );
};
